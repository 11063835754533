import SwiperCore, { Scrollbar, A11y, EffectFade } from 'swiper';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TestimonialSliderItem, TestimonialSliderItemProps } from './TestimonialSliderItem/TestimonialSliderItem';
import styles from './TestimonialSlider.module.scss';
import 'swiper/scss';
import 'swiper/scss/effect-fade';

SwiperCore.use([Scrollbar, A11y]);

export interface TestimonialSliderProps {
	className?: string;
	slides?: TestimonialSliderItemProps[];
}

export const TestimonialSlider: React.FC<TestimonialSliderProps> = ({ slides }) => {
	const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
	const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

	const handlePrevClick = () => {
		if (swiperInstance) {
			swiperInstance.slidePrev();
		}
	};

	const handleNextClick = () => {
		if (swiperInstance) {
			swiperInstance.slideNext();
		}
	};

	const handleSlideChange = () => {
		if (swiperInstance) {
			setActiveSlideIndex(swiperInstance.realIndex);
		}
	};

	return (
		<Swiper
			loop
			spaceBetween={50}
			slidesPerView={1}
			className={styles.TestimonialSlider}
			breakpoints={{
				// when window width is >= 320px
				320: {
					slidesPerView: 1,
					spaceBetween: 0,
				},
			}}
			onSwiper={setSwiperInstance}
			onSlideChange={handleSlideChange}
			modules={[EffectFade]}
			effect={'fade'}
			fadeEffect={{ crossFade: true }}
			speed={800}
		>
			{slides.map((slide, index, arr) => (
				<SwiperSlide key={index}>
					<TestimonialSliderItem
						{...slide}
						slideNumber={arr.length}
						onHandlePrevClick={handlePrevClick}
						onHandleNextClick={handleNextClick}
						isActive={index === activeSlideIndex}
					/>
				</SwiperSlide>
			))}
		</Swiper>
	);
};
