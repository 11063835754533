import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useState } from 'react';
import { TestimonialSlider } from 'ui/components/3-organisms/TesimonialSlider/TestimonialSlider';
import { GLOBAL_THEME_DEFAULT } from '_constants';

// Contexts
import { useSiteContext } from 'application/contexts/SiteContext';

export const TestimonialSliderFeature: React.FC<Content.TestimonialSlider> = ({ content }) => {
	const dictionary = useDictionaryContext();
	const SiteContext = useSiteContext();
	const globalTheme: string = SiteContext.settings.globalTheme?.setTheme ?? GLOBAL_THEME_DEFAULT;

	const navigationPrevLabel = dictionary.getValue('Navigation.Prev');
	const navigationNextLabel = dictionary.getValue('Navigation.Next');
	const [activeSlideIndex] = useState<number>(0);

	return (
		<TestimonialSlider
			slides={content?.properties?.slides?.map((slide) => {
				const author = slide.content?.properties?.include_ref_author?.properties;
				return {
					heading: slide.content?.properties?.headline,
					bgColor: slide.settings?.properties?.color,
					author: {
						name: author?.fullName,
						description: author?.bio,
						image: author?.image?.url,
					},
					navigationPrevLabel: navigationPrevLabel,
					navigationNextLabel: navigationNextLabel,
					isActive: activeSlideIndex === content?.properties?.slides?.indexOf(slide),
					globalTheme: globalTheme,
				};
			})}
		/>
	);
};
